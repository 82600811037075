import React,{Component} from 'react'
import Nav from './Nav';
import Footer from './Footer';
import {Button} from 'semantic-ui-react'
import {Timeline,TimelineEvent} from 'react-event-timeline';

class Events extends Component
{
    render()
    {
        return <div className="about-body farming">
            <Nav white={1}/>
            <div className="top-cover">
                <div className="item">
                    <h3>our:</h3>
                    <h2>Events and Timeline</h2>
                    <p>
                        Vestibulum egestas nulla quis dignissim hendrerit. Nullam consequat leo id est molestie, id porta metus mattis. Pellentesque velit ante, blandit a iaculis ac, convallis sed nisl. Sed ac tortor facilisis, placerat libero convallis, egestas mauris. Mauris sed efficitur nibh. Etiam id velit vitae lacus viverra maximus eget ac arcu. 
                    </p>
                    <Button>More Info</Button>
                </div>
                <div className="item">
                    <Timeline>
                        <TimelineEvent 
                            title="something"
                            createdAt="2018-3-8"
                            icon={<i className="material-icons md-18">1</i>}
                        >
                        I received the payment for $543. Should be shipping the item within a couple of hours.                          
                        </TimelineEvent>
                        <TimelineEvent 
                            title="something"
                            createdAt="2018-3-8"
                            icon={<i className="material-icons md-18">2</i>}
                        >
                        I received the payment for $543. Should be shipping the item within a couple of hours.                          
                        </TimelineEvent>
                        <TimelineEvent 
                            title="something"
                            createdAt="2018-3-8"
                            icon={<i className="material-icons md-18">3</i>}
                        >
                        I received the payment for $543. Should be shipping the item within a couple of hours.                          
                        </TimelineEvent>
                        <TimelineEvent 
                            title="something"
                            createdAt="2018-3-8"
                            icon={<i className="material-icons md-18">4</i>}
                        >
                        I received the payment for $543. Should be shipping the item within a couple of hours.                          
                        </TimelineEvent>
                    </Timeline>
                    {/* <div className="card-cover flex width-50 ">
                        <div>
                            <h3>Something</h3>
                            <span>Cras efficitur vulputate nisi ac elementum. Donec in feugiat ipsum.</span>
                        </div>
                        <div>
                            <h3>Something</h3>
                            <span>Cras efficitur vulputate nisi ac elementum. Donec in feugiat ipsum.</span>
                        </div>
                    </div> */}
                </div>            
            </div>
            <Footer/>
        </div>
    }
}

export default Events;

