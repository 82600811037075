import React,{Component} from 'react';
import Nav from './Nav';
import {Button,Input,Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import '../assets/css/style.css';
import Footer from './Footer';

class Home extends Component
{
    constructor(props)
    {
        super(props);
    }
    render()
    {
        return (
            <div className="body">
                <Nav/>
                <div className="top">
                    <div className="content">
                        <div duration={5000}>
                        </div>
                        {/* <div duration={5000}>
                        <p><i>Gajurmukhi Herbal Pvt. Ltd.</i></p>
                        </div> */}
                        {/* <div duration={1500}>
                            <h1>The essence</h1>
                        </div> */}
                        {/* <div duration={1800}>
                            <h1>of nature from</h1>
                        </div> */}
                        <div  duration={2100}>
                            <h1>"A TRUE ESSENCE OF</h1>
                        </div>
                        <div  duration={2100}>
                            <h1>NATURE BROUGHT TO YOU</h1>
                        </div>
                        <div  duration={2400}>
                            <h1>FROM HIMALAYAS"</h1>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="table">
                        <div>
                            <img src={require('../assets/anim/chemical.gif')}/>
                            <h3>Processing Capacity</h3>
                            <p>
                            4500 Kg processing capacity per day.
                            </p>
                           <a href="/pf"><Button secondary>Detail</Button></a>
                         </div>
                        <div>
                            <img src={require('../assets/anim/env.png')}/>
                            <h3>Sites</h3>
                            <p>
                            <b>8</b> farming sites.
                            </p>
                           <a href="/about"><Button secondary>Detail</Button></a>
                         </div>
                        <div>
                            <img src={require('../assets/cert/organic.png')}/>
                            <h3>Certification</h3>
                            <p>
                                Organic certified by <b>Onecert</b>
                            </p>
                           <a href="/certification"><Button secondary>Detail</Button></a>
                         </div>
                        <div>
                            <img src={require('../assets/anim/sustainability.png')}/>
                            <h3>Sustainability</h3>
                            <p>
                            Ensure sustainability of products.
                            </p>
                            <a href="/sustainability"><Button secondary>Detail</Button></a>
                        </div>
                    </div>
                </div>
                {/* <div className="word-only">
                    <img src={require('../assets/effect.png')} className="cover" />
                    <p>
                        <img src={require('../assets/bottle-back.png')} />
                    </p>
                </div> */}
                <div className="section cover">
                    <img src={require('../assets/19.jpg')} className="cover"/>
                    <div className="container">
                        <div className="title">
                        </div>
                        <div className="cards">
                            <div className="content">
                                <h1>Farming in “700+” hectare of private and community forest land</h1>
                                <Button secondary>See All</Button>
                            </div>
                            <div className="content">
                                <p>
                                <h2>About Us</h2>
                                <span style={{"font-size":"1.1em","line-height":"20px"}}>GHPL centres to give Natural, organic, Health- Enhancing, Undefiled Essential oil to our clients.GHPL safeguards the sustainability of the oils...</span>
                                </p>
                                <Button secondary>See All</Button>
                            </div>
                            <div className="content">
                                <h1>Major Products</h1>
                                <ol style={{"font-size":"1.2em","line-height":"26px"}}>
                                    <li>
                                    Jatamansi oil   (Nardostachys  grandiflora)
                                    </li>
                                    <li>
                                    Wintergreen oil     (Gaultheria fragrantissima)
                                    </li>
                                    <li>
                                    Juniper oil        (Juniperus communis)
                                    </li>
                                </ol>
                                <Button secondary>See All</Button>
                            </div>
                            <div className="content">
                                <h1>Source to you</h1>
                                <p style={{"color":"#555","font-weight":"100","font-size":"1.8em"}}>We manage to deliver the finished product from the seeds to the bottle.</p>
                                {/* <p>Visit the links to see our certifications.</p> */}
                                <Button secondary>
                                    See All
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default Home;