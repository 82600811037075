import React,{Component} from 'react'
import Nav from './Nav';
import Footer from './Footer';
import {Button} from 'semantic-ui-react'
import {Timeline,TimelineEvent} from 'react-event-timeline';

class Source extends Component
{
    render()
    {
        return <div className="about-body farming source">
            <Nav white={1}/>
            <div className="top-cover">
                <div className="item">
                    <h3>our:</h3>
                    <h2>Source to You</h2>
                    <p>
                        Vestibulum egestas nulla quis dignissim hendrerit. Nullam consequat leo id est molestie, id porta metus mattis. Pellentesque velit ante, blandit a iaculis ac, convallis sed nisl. Sed ac tortor facilisis, placerat libero convallis, egestas mauris. Mauris sed efficitur nibh. Etiam id velit vitae lacus viverra maximus eget ac arcu. 
                        Vestibulum egestas nulla quis dignissim hendrerit. Nullam consequat leo id est molestie, id porta metus mattis. Pellentesque velit ante, blandit a iaculis ac, convallis.
                    </p>
                    <p>
                    Vestibulum egestas nulla quis dignissim hendrerit. Nullam consequat leo id est molestie, id porta metus mattis. Pellentesque velit ante, blandit a iaculis ac, convallis.
                    </p>
                    <p>
                        Vestibulum egestas nulla quis dignissim hendrerit. Nullam consequat leo id est molestie, id porta metus mattis. Pellentesque velit ante, blandit a iaculis ac, convallis sed nisl. Sed ac tortor facilisis, placerat libero convallis, egestas mauris. Mauris sed efficitur nibh. Etiam id velit vitae lacus viverra maximus eget ac arcu. 
                        Vestibulum egestas nulla quis dignissim hendrerit. Nullam consequat leo id est molestie, id porta metus mattis. Pellentesque velit ante, blandit a iaculis ac, convallis.
                    </p>
                    <Button>Download Brochure</Button>
                </div>
                <div className="item">
                    <div className="card-cover flex width-50">
                        <div>
                            <h1>1</h1>
                            <h3>Cultivation</h3>
                            <span>Cras efficitur vulputate nisi ac elementum. Donec in feugiat ipsum.</span>
                            <img src={require('../assets/sub.jpg')}/>
                        </div>
                        <div>
                            <h1>2</h1>
                            <h3>Processing</h3>
                            <span>Cras efficitur vulputate nisi ac elementum. Donec in feugiat ipsum.</span>
                            <img src={require('../assets/pics/10.jpg')}/>
                        </div>
                        <div>
                            <h1>3</h1>
                            <h3>Refining</h3>
                            <span>Cras efficitur vulputate nisi ac elementum. Donec in feugiat ipsum.</span>
                            <img src={require('../assets/pics/11.jpg')}/>
                        </div>
                        <div>
                            <h1>4</h1>
                            <h3>Delivery</h3>
                            <span>Cras efficitur vulputate nisi ac elementum. Donec in feugiat ipsum.</span>
                            <img src={require('../assets/essential-oil.png')}/>
                        </div>
                    </div>
                </div>            
            </div>
            <Footer/>
        </div>
    }
}

export default Source;

