import React,{Component} from 'react'
import Nav from './Nav';
import {Button} from 'semantic-ui-react'
import Footer from './Footer';
import {Link} from 'react-router-dom'

class Eoil extends Component
{
    constructor(props)
    {
        super(props)
        this.oil = [
            [
                "Jatamansi oil",
                "Nardostachys grandiflora",
                "The volatile oil is obtained by steam distillation from the rhizomes of Nardostachys grandiflora, commonly called as Jatamansi, Spikenard or Nard"
            ],
            [
                "Wintergreen oil",
                "Gaultheria fragrantissima",
                "Wintergreen blends well with Bergamot, Basil, Cypress, Geranium, Lavender, Lemongrass, Marjoram, and Peppermint essential oils. Depending on your uses for this oil, substitutes may include White Fir, Peppermint, Spearmint, or Cypress essential oils."
            ],
            [
                "Juniper oil",
                "Juniperus communis",
                "The health benefits of juniper essential oil can be attributed to its properties as an antiseptic, sudorific, antirheumatic, depurative, antispasmodic, stimulating, stomachic, astringent, carminative, diuretic, rubefacient, vulnerary, and a tonic substance."
            ],
            [
                "Zanthoxylum oil",
                "Zanthoxylum aramatum",
                "Zanthoxylum (including genus Fagara) is a genus of about 250 species of deciduous and evergreen trees and shrubs in the citrus or rue family, Rutaceae, native to warm temperate and subtropical areas worldwide."
            ],
            [
                "Yellow zedoary oil",
                "Curcuma zedoaria",
                "This oil has long, been a part of the folk medicine. Zedoary essential oil is normally extracted by steam distillation of the rhizomes of the plant Curcuma zedoaria, which is a member of the ginger family Zingiberaceae."
            ],
            [
                "Thuja oil",
                "Platycladus orientalis",
                "Thuja is a tree. The leaves and leaf oil are used as a medicine. Thuja is used for respiratory tract infections such as bronchitis, bacterial skin infections, and cold sores."
            ],
            [
                "Cypresswood oil",
                "Juniperus Pseudosabina",
                "Because cypress oil is a diuretic, it helps the body remove excess water and salt that can lead to fluid retention. It also stimulates circulation by increasing blood flow. Use cypress oil topically to treat varicose veins"
            ],
            [
                "Mentha oil",
                "Mentha arvensis",
                "Mentha (also known as mint, from Greek míntha,[2] Linear B mi-ta)[3] is a genus of plants in the family Lamiaceae (mint family).[4] It is estimated that 13 to 18 species exist, and the exact distinction between species is still unclear."
            ],
            [
                "Calamus oil",
                "Acorus calamus",
                "Calamus essential oil is extracted from the Acorus Calamus herb. Calamus herb is very popular for its curative powers towards many diseases like epilepsy, constipation, flatulence, colic, and rheumatic swellings. "
            ],
            [
                "Citronella oil",
                "Cymbopogon winterianus (Java type)",
                "As a type of natural diaphoretic and diuretic agent, citronella can increase sweating and urination that draws toxins out of the body. Drinking a combination of one to two pure citronella essential oil"
            ],
            [
                "Lemongrass oil",
                "Cymbopogon flexuosus",
                "Cymbopogon, better known as lemongrass, is a genus of Asian, African, Australian, and tropical island plants in the grass family. Some species are commonly cultivated as culinary and medicinal herbs"
            ],
            [
                "Ginger oil",
                "Zingiber officinale",
                "It can be taken internally to treat health conditions or rubbed topically with a carrier oil on an area of pain. Today, ginger essential oil is used to treat nausea, upset stomach, menstrual disorders, "
            ],
            [
                "Turmeric oil",
                "Curcuma longa",
                "Turmeric has a lengthy history as a medicine, spice and coloring agent, and turmeric essential oil is an extremely impressive natural health agent — one that has some of the most promising anticancer effects around."
            ],
            [
                "Chamomile oil",
                "Matricaria chamomilla",
                "Aromatic Profile and Blending Information of Roman Chamomile Essential Oil. Blends Well With: floral oils such as geranium oil, lavender oil, rose oil, ylang-ylang oil, and citrus oils bergamot oil, l"
            ],
            [
                "Palmarosa oil",
                "Cymbopogon martini",
                "Palmarosa, also known as Indian or Turkish geranium, rosha or motia, is a type of tropical grass native to India and is known for its rich supply of volatile oils."
            ],
            [
                "Patchouli oil",
                "Pogostemon cablin",
                "The health benefits of patchouli essential oil can be attributed to its properties as an antidepressant, antiphlogistic, antiseptic, aphrodisiac, astringent, cicatrizant, cytophylactic, deodorant, diuretic,"
            ],
            [
                "Cinnamomum oil",
                "Cinnamomum Tamala",
                "Cinnamon leaf oil can be used as an additive in soaps and a flavoring to seasonings. When used in aromatherapy – diffused, applied topically (I recommend diluting with a mild essential oil or mixing in your favorite cream, lotion, or shampoo)"
            ],
            [
                "Eucalyptus oil",
                "Eucalyptus globulus",
                "Eucalyptus essential oil is highly effective for treating respiratory problems, such as asthma, bronchitis, COPD, pneumonia and even tuberculosis. "
            ]
        ]
    }
    render()
    {
        return <div className="about-body farming product">
        <Nav white={1}/>
        <div className="top-cover">
            <div className="item">
                <h3>Our Products:</h3>
                <h2>Essential Oils</h2>
                <p>
                Essential oils have been used for thousands of years in various cultures for medicinal and health purposes. Because of their antidepressant, stimulating, detoxifying, antibacterial, antiviral and calming properties, essential oils are recently gaining popularity as a natural, safe and cost-effective therapy for a number of health concerns.                </p>
                <Button>More Info</Button>
            </div>
            <div className="item">
                <div className="card-cover flex width-50 ">
                    {
                        this.oil.map(function(data,i){
                            return <div className="card">
                                    <h3>
                                        {data[0]}
                                    </h3>
                                    <span>
                                        {data[2].substr(0,100)}
                                        <p>
                                        <Link to={"/product/"+data[0]}>Read More</Link>
                                        </p>
                                    </span>
                            </div>
                        })
                    }
                </div>
            </div>            
        </div>
        <Footer clean/>
        </div>
    }
}

export default Eoil;

