import React,{Component} from 'react'
import Nav from './Nav';
import Footer from './Footer';
import {Button} from 'semantic-ui-react'
import {Link} from 'react-router-dom'

class Qc extends Component
{
    render()
    {
        return <div className="about-body farming">
            <Nav white={1}/>
            <div className="top-cover">
                <div className="item">
                    <h3>Quality Control:</h3>
                    <h2>Why </h2>
                    <p>
                    Quality control is essential to building a successful business that delivers products that meet or exceed customers' expectations. It also forms the basis of an efficient business that minimizes waste and operates at high levels of productivity.                        
                    </p>
                    <h2>Gajurmukhi’s Quality Control Measures</h2>
                    <p>
                    <ul>
                        <li>Traceability of the Produts</li>
                        <li>Proper Batch system of the products</li>
                        <li>Cultivation, Processing and harvesting done under the supervision of experienced peoples.</li>
                        <li>Testing of the oils after every Batch</li>
                    </ul>
                    </p>
                    <Button>More Info</Button>
                </div>
                <div className="item">
                    <div className="card-cover flex width-50 ">
                        <div>
                            <img src={require('../assets/img.jpg')}/>
                        </div>
                        <div>
                            <img src={require('../assets/essential-oil.png')}/>
                        </div>
                        <div>
                            <img src={require('../assets/sub.jpg')}/>
                        </div>
                        <div>
                            <img src={require('../assets/intro.jpg')}/>
                        </div>
                    </div>
                </div>            
            </div>
            {/* <div className="gallery-card">
            {
                this.ir.map(function(data,i){
                    let pic = require('../assets/pics/'+i+".jpg")
                    return <div key={i} className="card">
                        <img src={pic}/>
                    </div>
                })
            }
            </div> */}
            <Footer/>
        </div>
    }
}

export default Qc;

