import React,{Component} from 'react'
import Nav from './Nav';
import Footer from './Footer';

class Certificatoin extends Component
{
    render()
    {
        return <div className="about-body certification">
            <Nav />
            <div className="flex space-around content">
                <div className="card">
                    <img src={require('../assets/cert/eu.png')}/>
                    <p>
                        <h4>EU certified</h4>
                        <span>
                        Certification is about conformity assessment (testing and certification) in order to declare compliance with EU regulatory requirements...</span>
                        <a href="#">Visit Site</a>
                    </p>
                </div>
                <div className="card">
                    <img src={require('../assets/cert/onecert.png')}/>
                    <p>
                        <h4>Onecert certified</h4>
                        <span>
                        OneCert’s family of companies work closely together to provide multiple certification options.OneCert, Inc. was founded in 2003 by Sam Welsch to provide certification...                        
                        </span>
                        <a href="#">Visit Site</a>
                    </p>
                </div>
                <div className="card">
                    <img src={require('../assets/cert/organic.png')}/>
                    <p>
                        <h4>100% Organic</h4>
                        <span>
                        Organic farming is a method of crop and livestock production that involves much more than choosing not to use pesticides, fertilizers, genetically modified organisms, antibiotics and growth hormones.                        </span>
                        <a href="#">Visit Site</a>
                    </p>
                </div>
                <div className="card">
                    <img src={require('../assets/cert/since1998.png')}/>
                    <p>
                        <h4>15 Years of Experience</h4>
                        <span>
                            One of the major reson to that makes us stand out amoung others is the level of experience in domain field, and number of satisfied cliets yet.
                        </span>
                        <a href="#">Visit Site</a>
                    </p>
                </div>
                <div className="card">
                    <img src={require('../assets/cert/squarespace.png')}/>
                    <p>
                        <h4>Bio Group certified</h4>
                        <span>
                        Since the 1st October 2016, BioGro is owned by the Soil & Health Association. The Soil & Health Association is the largest membership organisation supporting organic food and farming in New Zealand.                        </span>
                        <a href="#">Visit Site</a>
                    </p>
                </div>
                <div className="card">
                    <img src={require('../assets/cert/usda.png')}/>
                    <p>
                        <h4>USDA certified</h4>
                        <span>
                        Organic is a labeling term that indicates that the food or other agricultural product has been produced through approved methods. The organic standards describe the specific requirements that must be verified by a USDA                        </span>
                        <a href="#">Visit Site</a>
                    </p>
                </div>
            </div>
            <Footer/>
        </div>
    }
}

export default Certificatoin;

