import React,{Component} from 'react';
import '../assets/css/style.css';

class Construction extends Component {
  render() {
    return (
      <div className="construction">
            <h1>The webpage is under construction please mail us at <a href="mailto:info@gajurmukhiherbal.com">info@gajurmukhiherbal.com</a></h1>
      </div>
    );
  }
}

export default Construction;



