import React, { Component } from 'react';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom';
import Home from './page/Home';
import 'semantic-ui-css/semantic.min.css';
import './assets/css/style.css';
import Construction from './page/Construction';
import About from './page/About';
import Gallery from './page/Gallery';
import Eoil from './page/Eoil';
import Doil from './page/Doil';
import Extracts from './page/Extracts';
import Dryflowers from './page/Dryflowers';
import Pf from './page/Pf';
import Qc from './page/Qc';

import Farming from './page/Farming';
import Source from './page/Source';
import Sustainability from './page/Sustainability';
import Contact from './page/Contact';
import Events from './page/Events';
import Organic from './page/Organic';
import Certification from './page/Certification';

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Route path="/farming" component={Farming}/>
          <Route path="/source" component={Source}/>
          <Route path="/sustainability" component={Sustainability}/>
          <Route path="/contact" component={Contact}/>
          <Route path="/events" component={Events}/>
          <Route path="/organic" component={Organic}/>
          <Route path="/certification" component={Certification}/>

          <Route exact path="/" component={Home}/>
          <Route path="/about" component={About}/>
          <Route exact path="/gallery" component={Gallery}/>
          <Route exact path="/eoil" component={Eoil}/>
          <Route path="/doil" component={Doil}/>
          <Route path="/extracts" component={Extracts}/>
          <Route path="/dryflowers" component={Dryflowers}/>
          <Route path="/pf" component={Pf}/>
          <Route path="/Qc" component={Qc}/>
          <Route path="/construction" component={Construction}/>
        </div>
      </Router>
    );
  }
}

export default App;
