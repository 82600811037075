import React,{Component} from 'react'
import Nav from './Nav';
import {Button} from 'semantic-ui-react'
import Footer from './Footer';
import {Link} from 'react-router-dom'

class Extracts extends Component
{
    constructor(props)
    {
        super(props)
        this.oil = [
            [
                "Green Tea Extract (50% Polyphenol)",
                "",
                "Among the catechins in green tea, epigallocatechin gallate (EGCG) is the most researched and thought to provide the most health benefits."
            ],
        ]
    }
    render()
    {
        return <div className="about-body farming product">
        <Nav white={1}/>
        <div className="top-cover">
            <div className="item">
                <h3>Our Services:</h3>
                <h2>Our Farming Sites</h2>
                <p>
                    Vestibulum egestas nulla quis dignissim hendrerit. Nullam consequat leo id est molestie, id porta metus mattis. Pellentesque velit ante, blandit a iaculis ac, convallis sed nisl. Sed ac tortor facilisis, placerat libero convallis, egestas mauris. Mauris sed efficitur nibh. Etiam id velit vitae lacus viverra maximus eget ac arcu. 
                </p>
                <Button>More Info</Button>
            </div>
            <div className="item">
                <div className="card-cover flex width-50 ">
                    {
                        this.oil.map(function(data,i){
                            return <div className="card">
                                    <h3>
                                        {data[0]}
                                    </h3>
                                    <span>
                                        {data[2].substr(0,100)}
                                        <p>
                                        <Link to={"/product/"+data[0]}>Read More</Link>
                                        </p>
                                    </span>
                            </div>
                        })
                    }
                </div>
            </div>            
        </div>
        <Footer clean/>
        </div>
    }
}

export default Extracts;

