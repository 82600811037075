import React,{Component} from 'react'
import Nav from './Nav';
import Footer from './Footer';

class Pf extends Component
{
    render()
    {
        return <div className="about-body corp">
            <Nav white={1}/>
            <div className="header">
                <div className="section about two flex" id="about">
                    <div className="title">
                        <h1>
                            Processing Facility.
                        </h1>
                        <h2>
                            <a href="#">Why GHPL</a>
                            <a href="#">Mission & Vision</a>
                            <a href="#">Contact</a>
                            <a href="#">Products</a>
                        </h2>
                    </div>
                </div>
            </div>
            <div className="two">
                <div className="item">
                    <img src={require('../assets/sub.jpg')}/>
                    <h4>Title</h4>
                    <h3>Some basic information</h3>
                    <p>
                        Founder and CEO Sarah Kauss talks about entrepreneurship, international growth, saving the planet and giving back with the U.K's City A.M.                    
                    </p>
                </div>
                <div className="item">
                    <img src={require('../assets/sub.jpg')}/>
                    <h4>Title</h4>
                    <h3>Some basic information</h3>
                    <p>
                        Founder and CEO Sarah Kauss talks about entrepreneurship, international growth, saving the planet and giving back with the U.K's City A.M.                    
                    </p>
                </div>
                <div className="item">
                    <img src={require('../assets/sub.jpg')}/>
                    <h4>Title</h4>
                    <h3>Some basic information</h3>
                    <p>
                        Founder and CEO Sarah Kauss talks about entrepreneurship, international growth, saving the planet and giving back with the U.K's City A.M.                    
                    </p>
                </div>
                <div className="item">
                    <img src={require('../assets/sub.jpg')}/>
                    <h4>Title</h4>
                    <h3>Some basic information</h3>
                    <p>
                        Founder and CEO Sarah Kauss talks about entrepreneurship, international growth, saving the planet and giving back with the U.K's City A.M.                    
                    </p>
                </div>
            </div>
            <Footer/>
        </div>
    }
}

export default Pf;

