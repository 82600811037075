import React,{Component} from 'react';
import Nav from './Nav';
import Footer from './Footer';
import {Button} from 'semantic-ui-react';
import Fade from 'react-reveal/Fade';

class About extends Component
{
    render()
    {
        return <Fade duration={500}>
        <div className="about-body">
            <Nav white={1}/>
            <div className="header">
                <div className="section about two flex" id="about">
                    <div className="title">
                        <h1>
                            About us: getting closer to us.
                        </h1>
                    </div>
                </div>
            </div>
            <div className="section about flex">
                <div className="content bar">
                    <h3>About Us:</h3>
                    Founded in 2002,  Initially started with few single Metal Distilling Vessel, GHPL now claims best in class processing facility in Nepal (4 Metric Ton crude material processing per day). GHPL centres to give Natural, organic, Health- Enhancing, Undefiled Essential oil to our clients.
                    GHPL just manages the oils that are cultivated and Harvested under our supervision. We beleive the nature of the oils that lone we produce. Customers are contributing their opportunity and trust on us and we mean not to disappoint them.
                    We trust essential oils are fragile, valuable and genuine endowment of Nature to humankind in this way it is an unquestionable requirement to guarantee the maintainability of those blessing. GHPL safeguards the sustainability of the oils by putting resources into development of harvests and claims 200 Hectares of private land and 300 hectares of government lease lands.
                </div>
                <div className="content">
                    <img src={require('../assets/plants/4.png')} className="right"/>
                </div>
            </div>
            <div className="section about flex" id="mission">
                <div className="content">
                    <img src={require('../assets/plants/2.png')} className="left"/>
                </div>
                <div className="content bar">
                    <h3>Mission and Value Statement:</h3>
                    <ul>
                        <li>
                        Ensure Sustainable farming.
                        </li>
                        <li>
                        Provide 100% Organic products to the local and International market.
                        </li>
                        <li>
                        Work in Public private Partnership (PPP).
                        </li>
                        <li>
                        Utilize locally available Human resources and Natural Resources.
                        </li>
                        <li>
                        Provide workshops and training to local peoples so that they can understand better.
                        </li>
                    </ul>
                </div>
            </div>
            <div className="section about flex" id="why">
                <div className="content bar">
                    <h3>Why GHPl??</h3>
                    <ul>
                        <li>
                        Choosing GHPL means you are choosing Essential oils that are Gently Distilled from the plants.
                        </li>
                        <li>
                        processing capacity of 4 Metric Tonnes per day. It is a difficult task to ensure the raw materials which are Timely and properly Harvested.
                        </li>
                        <li>
                        GHPL possess for huge Distilling Plant Therefore Gajurmukhi has been Doing organic farming in 120 Hectares of private lands and 400 Hectares of Community Forest lands.
                        </li>
                        <li>
                        Team of Dedicated members and Experts are responsible from farming to harvesting and also on distilling facility.
                        </li>
                        <li>
                        We are Organic certified for all our products By EU and US-NOP.
                        </li>
                        <li>
                        Proper Batch system is mentioned of the raw materials that is obtained from the fields in order to ensure there is Traceability of all the products.
                        </li>
                        <li>
                        GCMS (Gas Chromatography Mass Spectrometer)  analysis is carried in every batch of oils to check only the purest quality goes into our customer hands.
                        </li>
                        <li>
                        GHPL provides employment opportunities for local peoples. The  Peoples that work in the fields are mostly females while most of the peoples are minority groups. We have also been working in hand in hand with Different Community Forest User Groups(CFUG’s)
                        </li>
                        <li>
                        The reason Gajurmukhi differs from the rest of Essential oil companies is, GHPL provides better services and customer satisfaction.
                        </li>
                    </ul>
                </div>
                <div className="content">
                    <img src={require('../assets/svg/flower.png')} className="right"/>
                </div>
            </div>
            <Footer/>
        </div>
        </Fade>
    }
}
export default About;
