import React,{Component} from 'react';
import Nav from './Nav';
import Footer from './Footer';
import {Button} from 'semantic-ui-react';

class Gallery extends Component
{
    render()
    {
        return <div className="about-body">
            <Nav white={1}/>
            <div className="header two">
                <div className="section about two flex" id="about">
                    <div className="title">
                        <h1>
                            Gallary : Get to know us by our work.
                        </h1>
                        <h2>
                            {/* GHPL is always open for any query and we love sharing clear and precise information.  */}
                            <a href="#">Why GHPL</a>
                            <a href="#">Mission & Vision</a>
                            <a href="#">Contact</a>
                            <a href="#">Products</a>
                        </h2>
                    </div>
                </div>
            </div>
            <div className="section about gallery">
                <div className="card">
                    <img src={require('../assets/gallery/1.jpg')}/>
                    <label>
                        <h3>Title of the picture</h3>
                        <p>
                            Here is a long story for the picture shown above in the frame
                        </p>
                    </label>
                </div>
                <div className="card">
                    <img src={require('../assets/gallery/5.jpg')}/>
                    <label>
                        <h3>Title of the picture</h3>
                        <p>
                            Here is a long story for the picture shown above in the frame
                        </p>
                    </label>
                </div>
                <div className="card">
                    <img src={require('../assets/gallery/7.jpg')}/>
                    <label>
                        <h3>Title of the picture</h3>
                        <p>
                            Here is a long story for the picture shown above in the frame
                        </p>
                    </label>
                </div>
                <div className="card">
                    <img src={require('../assets/gallery/6.jpg')}/>
                    <label>
                        <h3>Title of the picture</h3>
                        <p>
                            Here is a long story for the picture shown above in the frame
                        </p>
                    </label>
                </div>
                <div className="card">
                    <img src={require('../assets/gallery/2.jpg')}/>
                    <label>
                        <h3>Title of the picture</h3>
                        <p>
                            Here is a long story for the picture shown above in the frame
                        </p>
                    </label>
                </div>
                <div className="card">
                    <img src={require('../assets/gallery/3.jpg')}/>
                    <label>
                        <h3>Title of the picture</h3>
                        <p>
                            Here is a long story for the picture shown above in the frame
                        </p>
                    </label>
                </div>
            </div>
            <Footer/>
        </div>
    }
}
export default Gallery;
