import React,{Component} from 'react'
import Nav from './Nav';
import Footer from './Footer';
import {Button} from 'semantic-ui-react'

class Sustainability extends Component
{
    render()
    {
        return <div className="about-body farming">
            <Nav white={1}/>
            <div className="top-cover">
                <div className="item">
                    <h3>About us:</h3>
                    <h2>Sustainability</h2>
                    <p>
                        Vestibulum egestas nulla quis dignissim hendrerit. Nullam consequat leo id est molestie, id porta metus mattis.
                    </p>
                </div>
                <div className="item">
                    <div className="card-cover flex width-50 ">
                        <div>
                            <h3>Something</h3>
                            <span>Cras efficitur vulputate nisi ac elementum. Donec in feugiat ipsum.</span>
                        </div>
                        <div>
                            <h3>Something</h3>
                            <span>Cras efficitur vulputate nisi ac elementum. Donec in feugiat ipsum.</span>
                        </div>
                    </div>
                </div>            
            </div>
            <div className="gallery-card">
                <div className="card">
                    <img src={require('../assets/sust/1.jpg')}/>
                </div>
                <div className="card">
                    <img src={require('../assets/sust/7.jpg')}/>
                </div>
                <div className="card">
                    <img src={require('../assets/sust/9.jpg')}/>
                </div>
            </div>
            <Footer/>
        </div>
    }
}

export default Sustainability;

