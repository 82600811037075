import React,{Component} from 'react'
import Nav from './Nav';
import Footer from './Footer';
import {Button} from 'semantic-ui-react';

class Organic extends Component
{
    render()
    {
        return <div className="about-body farming">
            <Nav white={1}/>
            <div className="top-cover">
                <div className="item">
                    <h3>Our have:</h3>
                    <h2>Organic Practice</h2>
                    <p>
                        Vestibulum egestas nulla quis dignissim hendrerit. Nullam consequat leo id est molestie, id porta metus mattis. Pellentesque velit ante, blandit a iaculis ac, convallis sed nisl. Sed ac tortor facilisis, placerat libero convallis, egestas mauris. Mauris sed efficitur nibh. Etiam id velit vitae lacus viverra maximus eget ac arcu. 
                    </p>
                    <Button>More Info</Button>
                </div>
                <div className="item">
                    <div className="card-cover flex width-50 ">
                        <div>
                            <h3>Something</h3>
                            <span>Cras efficitur vulputate nisi ac elementum. Donec in feugiat ipsum.</span>
                        </div>
                        <div>
                            <h3>Something</h3>
                            <span>Cras efficitur vulputate nisi ac elementum. Donec in feugiat ipsum.</span>
                        </div>
                        <div>
                            <h3>Something</h3>
                            <span>Cras efficitur vulputate nisi ac elementum. Donec in feugiat ipsum.</span>
                        </div>
                        <div>
                            <h3>Something</h3>
                            <span>Cras efficitur vulputate nisi ac elementum. Donec in feugiat ipsum.</span>
                        </div>
                        <div>
                            <h3>Something</h3>
                            <span>Cras efficitur vulputate nisi ac elementum. Donec in feugiat ipsum.</span>
                        </div>
                        <div>
                            <h3>Something</h3>
                            <span>Cras efficitur vulputate nisi ac elementum. Donec in feugiat ipsum.</span>
                        </div>
                    </div>
                </div>            
            </div>
            <Footer/>
        </div>
    }
}

export default Organic;

