import React,{Component} from 'react';
import {Button,Input,Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import Fade from 'react-reveal/Fade'

class Nav extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            tick:false,
            selected:[
                ['About Us','...','/about'],
                ["Why GHPL","...","/about#why"],
                ["Mission & Vision","...","/about#mission"],
                ["Gallery","...","/gallery"]
            ],
            data:{
                company:[
                    ['About Us','...','/about'],
                    ["Why GHPL","...","/about"],
                    ["Mission & Vision","...","/about"],
                    ["Gallery","...","/gallery"]
                ],
                products:[
                    ["Essential Oil","...","/eoil"],
                    ["Fractional Distiled Oil","...","/doil"],
                    ["Extracts","...","/extracts"],
                    ["Dry Flowers","...","/dryflowers"],
                ],
                lab:[
                    ["Processing Facility","...","/pf"],
                    ["Quality Control","...","/qc"]
                ],
                organic:[
                    ["Farming","...","/farming"],
                    ["Organic Practice","...","/organic"],
                    ["Certification","...","/certification"]
                ],
                caring:[
                    // ["Corporate Social Responsibility","...","/csr"],
                    // ["Sustainability","...","/sustainability"],
                    // ["Events","...","/events"],
                    ["Source to you","...","/source"]
                ]
            }
        }
        this.menuSwitch = this.menuSwitch.bind(this);
    }
    menuSwitch=(sel)=>{
        sel = sel.toLowerCase();
        let data = this.state.data[sel];
        console.log(data);
        this.setState({
            tick:sel,
            selected:data
        })
    }
    
    render(){
        return (
            <div className="nav">
                {
                    (this.props.white)?
                        <img src={require('../assets/logo-white.png')} className="logo"/>
                    :
                        <img src={require('../assets/logo.png')} className="logo"/>
                }
                <input className="search" id="search" placeholder="Search"/>
                <Button secondary onClick={
                    function()
                    {
                        document.getElementById("menu").style="display:flex";
                    }
                }>Menu <Icon name="bars"/></Button>
                <div className="menu" id="menu">
                    <Fade duration={500}>
                    <div className="content">
                            <div className="flex space-between">
                                <div>
                                    <Link to="/"><img src={require('../assets/logo.png')} className="logo"/></Link>
                                </div>
                                <Button color="red" onClick={
                                    function(){
                                        document.getElementById('menu').style="display:none";
                                    }
                                }>Back <Icon name="remove"/></Button>
                            </div>
                            <div className="top-nav">
                            <ul>
                                <li>
                                    <a href="#" className={(this.state.tick=="company")?"focus":""} onClick={()=>this.menuSwitch("company")}>Company</a>
                                </li>
                                <li>
                                    <a href="#" className={(this.state.tick=="products")?"focus":""} onClick={()=>this.menuSwitch("products")}>Products</a>
                                </li>
                                <li>
                                    <a href="#" className={(this.state.tick=="laboratory")?"focus":""} onClick={()=>this.menuSwitch("lab")}>Laboratory</a>
                                </li>
                                <li>
                                    <a href="#" className={(this.state.tick=="organic")?"focus":""} onClick={()=>this.menuSwitch("organic")}>Organic</a>
                                </li>
                                <li>
                                    <a href="#" className={(this.state.tick=="caring")?"focus":""} onClick={()=>this.menuSwitch("caring")}>Caring</a>
                                </li>
                            </ul>
                            </div>
                            <div className="detail">
                                {
                                    this.state.selected.map(function(data,i){
                                        return <Link key={i} to={data[2]} className="card"   >
                                                <h5>{data[1]}</h5>
                                                <h2>{data[0]}</h2>
                                        </Link>
                                    })
                                }
                            </div>
                    </div>
                    </Fade>
                    <div className="dismiss-menu" onClick={
                                    function(){
                                        document.getElementById('menu').style="display:none";
                                    }
                                }/>
                </div>
            </div>
        )
    }
}

export default Nav;