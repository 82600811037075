import React,{Component} from 'react';
import {Button,Input,Icon} from 'semantic-ui-react';
import '../assets/css/style.css';


class Footer extends Component
{
    render()
    {
        return <div>              
            {
                (!this.props.clean)?
                <section>
                <div className="cert">
                    <div>
                        <img src={require('../assets/cert/eu.png')}/>
                        <img src={require('../assets/cert/onecert.png')}/>
                        <img src={require('../assets/cert/organic.png')}/>
                        <img src={require('../assets/cert/since1998.png')}/>
                        <img src={require('../assets/cert/squarespace.png')}/>
                        <img src={require('../assets/cert/usda.png')}/>
                    </div>
                </div>
                <div className="frame">
                    <div className="item">
                        <div>
                            <img src={require('../assets/logo-white.png')} className="logo"/>
                            <h5>"A TRUE ESSENCE OF
                            NATURE BROUGHT<br/> TO YOU
                            FROM THE HIMALAYAS"</h5>
                            <h2>Cultivation Sites</h2>
                            <p>
                            <Button >1</Button>
                            <Button >2</Button>
                            <Button >3</Button>
                            <Button >4</Button>
                            <Button >5</Button>
                                </p>
                            <h2>Production Sites</h2>
                            <p>
                            <Button >1</Button>
                            <Button >2</Button>
                            <Button >3</Button>
                            </p>
                        </div>
                    </div>
                    <div className="item">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3565.705695989247!2d88.15658011443959!3d26.657903877431732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e5b3307c110e05%3A0xb6bd24655b2735f7!2sGajurmukhi+Herbal+Pvt.+Ltd.!5e0!3m2!1sen!2snp!4v1517682495594" frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>
                </section>
            :
            null
            }
        <div className="footer">
            <div className="s-2">
                {/* <img src={require('../assets/logo.png')} className="logo"/> */}
                <div className="section four social-cards flex">
                    <div className="content">
                        <img src={require('../assets/img.jpg')} className="cover"/>
                        <p>
                            <Icon name="facebook"/>
                            Facebook
                        </p>
                    </div>
                    <div className="content">
                        <img src={require('../assets/19.jpg')} className="cover"/>
                        <p>
                            <Icon name="youtube"/>
                            Youtube
                        </p>
                    </div>
                    <div className="content">
                        <img src={require('../assets/19.jpg')} className="cover"/>
                        <p>
                            <Icon name="pencil"/>
                            Blog
                        </p>
                    </div>
                    <div className="content">
                        <img src={require('../assets/19.jpg')} className="cover"/>
                        <p>
                            <Icon name="photo"/>
                            Gallery
                        </p>
                    </div>
                </div>

                <p>
                    <a href="#">Site Map</a>
                    <a href="#">About Us</a>
                    <a href="#">Category</a>
                    <a href="#">Service Api</a>
                    <a href="#">Contact Us</a>
                </p>
                <p>
                    Copyright &copy; Gajurmukhi Herbal Pvt. Ltd. All right Reserves.<br/>
                    Copy of any content or resourecs without any agreed concern is prohibited
                </p>
            </div>
        </div>
    </div>
    }
}
export default Footer;
