import React,{Component} from 'react'
import Nav from './Nav';
import Footer from './Footer';
import {Button} from 'semantic-ui-react'
import {Link} from 'react-router-dom'

class Farming extends Component
{
    constructor(props)
    {
        super(props)
        this.ir = [
            "Lorem ipsum delor",
            "Lorem ipsum delor",
            "Lorem ipsum delor",
            "Lorem ipsum delor",
            "Lorem ipsum delor",
            "Lorem ipsum delor",
            "Lorem ipsum delor",
            "Lorem ipsum delor",
            "Lorem ipsum delor",
            "Lorem ipsum delor",
            "Lorem ipsum delor",
            "Lorem ipsum delor",
        ];
    }
    render()
    {
        return <div className="about-body farming product">
            <Nav white={1}/>
            <div className="top-cover">
                <div className="item">
                    <h3>Our Services:</h3>
                    <h2>Our Farming Sites</h2>
                    <p>
                    In Nepal, the economy is dominated by agriculture. In the late 1980s, it was the livelihood for more than 90 percent of the population, although only approximately 20 percent of the total land area was cultivable, it accounted for, on average, about 60 percent of the GDP and approximately 75 percent of exports.[1] Since the formulation of the Fifth Five-Year Plan (1975–80), agriculture has been the highest priority because economic growth was dependent on both increasing the productivity of existing crops and diversifying the agricultural base for use as industrial inputs                    </p>
                    <Button>More Info</Button>
                </div>
                <div className="item">
                    <div className="card-cover flex width-50 ">
                        <div>
                            <img src={require('../assets/intro.jpg')}/>
                            <h3>Emphasis on mass scale Farming</h3>
                            <span>We invest for scale</span>
                        </div>
                        <div>
                            <img src={require('../assets/community.jpg')}/>
                            <h3>Supporting Community</h3>
                            <span>Working Hand in hand with local community forest peoples.</span>
                        </div>
                        <div>
                            <img src={require('../assets/sub.jpg')}/>
                            <h3>Ownership on large scale of land</h3>
                            <span>250 Hectares of Private land and 500 Hectares of community forest lands.</span>
                            <p>
                            <Link to="/">Map</Link>
                            </p>
                        </div>
                    </div>
                </div>            
            </div>
            {/* <div className="gallery-card">
            {
                this.ir.map(function(data,i){
                    let pic = require('../assets/pics/'+i+".jpg")
                    return <div key={i} className="card">
                        <img src={pic}/>
                    </div>
                })
            }
            </div> */}
            <Footer/>
        </div>
    }
}

export default Farming;

