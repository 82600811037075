import React,{Component} from 'react'
import Nav from './Nav';
import Footer from './Footer';
import {Button} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
class Pf extends Component
{
    render()
    {
        return <div className="about-body farming">
            <Nav white={1}/>
            <div className="top-cover">
                <div className="item">
                    <h3>Our Services:</h3>
                    <h2>Processing Facility</h2>
                    <p>
                    Gajurmukhi’s Processing facility is located in mechinagar-10, Jhapa ( 45 mins flight from The capital
Kathmandu). GHPL lies in Eastern most part of Nepal, Nearer to Nepal- India boarder.
The process GHPL uses for the processing of Essential oils Is the steam Distillation. It is the oldest and
simplest process into practice for processing of the Essential oils. The process consist of Below enlisted
Instruments:
                        <ul>
                            <li>Boiler</li>
                            <li>Vessels</li>
                            <li>Condensor</li>
                            <li>Seperator</li>
                        </ul>
                    <h2>Gajurmukhi’s Processing Capacity</h2>
                    <p>
                    Processing of Essential oil itself is an art which require certain set of knowledge and skills.
Gajurmukhi’s Processing Facility is managed and Operated by experienced Employees. GHPl has the
processing capacity of 4.5 Metric Tonnes processing capacity per day which is one of the best that is
available in Nepal.
                    </p>
                    </p>
                    <Button>More Info</Button>
                </div>
                <div className="item">
                    <div className="card-cover flex width-50 ">
                        <div>
                            <h1>1</h1>
                            <span>
                                Water is heated and converted into Steam in the instrument known as Boiler also referred as The Mother of the plant.
                            </span>
                        </div>
                        <div>
                            <h1>2</h1>
                            <span>
                            The raw materials after harvesting from the fields or Wild Collection is Kept in the stainless Steel Vessels either of 500 Kg or 1000 Kg capacity.
                            </span>
                        </div>
                        <div>
                            <h1>3</h1>
                            <span>
                            Hot Water is applied from the Boiler to the Vessels.
                            </span>
                        </div>
                        <div>
                            <h1>4</h1>
                            <span>
                            The Hot water after meeting with the raw materials passes from the Vessel onto the Condensor 
                            </span>
                        </div>
                        <div>
                            <h1>5</h1>
                            <span>
                            The condenser works as cooling instrument for the hot water which is released from the Vessel and into the Seperator.
                            </span>
                        </div>
                        <div>
                            <h1>6</h1>
                            <span>
                            A separator is the Instrument which seperates the oil and water from the Mixture. The oils is stored separately and the water is stored separately.
                            </span>
                        </div>
                    </div>
                </div>            
            </div>
            {/* <div className="gallery-card">
            {
                this.ir.map(function(data,i){
                    let pic = require('../assets/pics/'+i+".jpg")
                    return <div key={i} className="card">
                        <img src={pic}/>
                    </div>
                })
            }
            </div> */}
            <Footer/>
        </div>
    }
}

export default Pf;

