import React,{Component} from 'react'
import Nav from './Nav';
import {Button} from 'semantic-ui-react'
import Footer from './Footer';
import {Link} from 'react-router-dom';

class Doil extends Component
{
    constructor(props)
    {
        super(props)
        this.oil = [
            [
                "Jatamansi oil",
                "Nardostachys grandiflora",
                "The volatile oil is obtained by steam distillation from the rhizomes of Nardostachys grandiflora, commonly called as Jatamansi, Spikenard or Nard"
            ],
            [
                "Wintergreen oil",
                "Gaultheria fragrantissima",
                "Wintergreen blends well with Bergamot, Basil, Cypress, Geranium, Lavender, Lemongrass, Marjoram, and Peppermint essential oils. Depending on your uses for this oil, substitutes may include White Fir, Peppermint, Spearmint, or Cypress essential oils."
            ]
        ]
    }
    render()
    {
        return <div className="about-body farming product">
        <Nav white={1}/>
        <div className="top-cover">
            <div className="item">
                <h3>Our Products:</h3>
                <h2>Fractional Distiled Oil</h2>
                <p>
                    Vestibulum egestas nulla quis dignissim hendrerit. Nullam consequat leo id est molestie, id porta metus mattis. Pellentesque velit ante, blandit a iaculis ac, convallis sed nisl. Sed ac tortor facilisis, placerat libero convallis, egestas mauris. Mauris sed efficitur nibh. Etiam id velit vitae lacus viverra maximus eget ac arcu. 
                </p>
                <Button>More Info</Button>
            </div>
            <div className="item">
                <div className="card-cover flex width-50 ">
                    {
                        this.oil.map(function(data,i){
                            return <div className="card">
                                    <h3>
                                        {data[0]}
                                    </h3>
                                    <span>
                                        {data[2].substr(0,100)}
                                        <p>
                                        <Link to={"/product/"+data[0]}>Read More</Link>
                                        </p>
                                    </span>
                            </div>
                        })
                    }
                </div>
            </div>            
        </div>
        <Footer clean/>
        </div>
    }
}

export default Doil;

